.ant-dropdown {
  width: 100%;
}

.ant-dropdown-arrow {
  display: none !important;
}
.ant-dropdown-menu {
  border-radius: 0px !important;
  border-top: 1px solid #000;
  background-color: #f3c522 !important;
}

.ant-dropdown-menu-item {
  /* color: #fff !important; */
}
